import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import Image from 'next/image'
import { useMemo } from 'react'

export interface itemType {
  id: string
  label: string
  description: string
  asset?: string
}

interface CardSelectorProps {
  label?: string
  items: itemType[]
  selectedItem: itemType
  setSelectedItem: (item: itemType) => void
  display?: 'horizontal' | 'vertical'
  nbOptionPerLine?: number
}

const CardSelector = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  display = 'vertical',
  nbOptionPerLine = 2,
}: CardSelectorProps) => {
  const displayClasses = useMemo(() => {
    if (display === 'vertical') {
      return 'grid-cols-1'
    }
    if (nbOptionPerLine > items?.length) {
      return `grid-cols-${items?.length}`
    }
    return `grid-cols-${nbOptionPerLine}`
  }, [display, items, nbOptionPerLine])

  return (
    <RadioGroup
      value={selectedItem}
      onChange={setSelectedItem}
      className="flex flex-col"
    >
      {label && (
        <RadioGroup.Label className="text-base font-semibold text-left text-slate-900">
          {label}
        </RadioGroup.Label>
      )}
      <div className={classNames('mt-4 grid gap-y-6 gap-x-6', displayClasses)}>
        {items.map((item) => (
          <RadioGroup.Option
            key={item.id}
            value={item}
            className={({ checked }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white shadow-sm focus:outline-none',
              )
            }
          >
            {({ checked }) => (
              <div className="w-full">
                {item?.asset && (
                  <div className="bg-[#F6F6F6] relative pt-1 w-full min-h-[200px]">
                    <Image
                      src={item?.asset}
                      alt={item.label}
                      layout="fill"
                      className="object-contain"
                    />
                  </div>
                )}
                <div className="flex flex-1 p-4">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="text-sm font-semibold text-left text-slate-900"
                    >
                      {item.label}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 text-left text-sm text-neutral-400"
                    >
                      {item.description}
                    </RadioGroup.Description>
                  </span>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-neutral-800 absolute top-4 right-5',
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    checked ? 'ring-2 ring-neutral-800' : 'border-transparent',
                    'pointer-events-none absolute inset-[0.5px] rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default CardSelector
